const mapAirtableResponse = x => x.edges.map(y => y.node.data)

const transformClubs = items => {
  return mapAirtableResponse(items).map(item => {
    return {
      cardIcon: item.Card_Icon,
      icon: item.Icon,
      name: item.Name,
      websiteFilterItem: item.Website_Filter_Item,
      websiteTableRowColour: item.Website_Table_Row_Colour?.split(
        "["
      )[1]?.replace("]", ""),
    }
  })
}
module.exports = transformClubs
