export default [
  'existing_article_card.existing_article',
  'testimonials.testimonials',
  'faqs.faqs',
  'accordion.accordion_items',
  'section_filter_items.filterable_item',
  'filter_item_section.section_filter_items',
  'find_us_page_venue_card_config.matching_filter_items_to_show',
  'job_search_filter.filter_groups',
  'name.filter_groups',
  'job_search_filter_groups.filter_items',
  'Newsletter.social_links',
  'VenueClubSchedule.variant_1',
  'VenueClubSchedule.variant_2',
];
