import { PageProps, graphql } from 'gatsby';
import * as React from 'react';
import DynamicTemplate from '../components/templates/DynamicTemplate/DynamicTemplate.component';
import useStoryblok from '../hooks/useStoryblok.hook';
import StoryblokEntryTemplateContext from '../interfaces/Storyblok/StoryblokEntryTemplateContext.interface';
import transformVenues from '../utils/transformVenueData';
import transformClubs from '../utils/transformClubs';
import transformBuildings from '../utils/transformBuildings';
import transformFacilities from '../utils/transformFacilities';
import VenuesContext, { Venue } from '../contexts/VenuesContext';
import FiltersContext from '../contexts/FiltersContext';
import LayoutContext from '../contexts/LayoutContext';

interface StoryblokPreviewProps {
  venues: any;
  clubs: any;
  buildings: any;
  facilities: any;
  breadcrumbs: any;
}

const StoryBlokPreview: React.FC<PageProps<StoryblokPreviewProps>> = ({ location, data }) => {
  const story = useStoryblok(null, location);

  if (!story) {
    return (
      <div className="py-40 flex justify-center">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  const content: any = story?.content;

  const context: StoryblokEntryTemplateContext = {
    name: story?.name,
    id: story?.id,
    full_slug: story?.full_slug,
    field_component: content.component,
    content: story?.content,
    first_published_at: story?.first_published_at,
  };

  const { venues, clubs, buildings, facilities, breadcrumbs } = data;
  const transformedVenues: Venue[] = transformVenues(venues).filter(
    (venue: Venue) => venue.status === 'Live' || venue.status === 'New' || venue?.status === 'Active',
  );
  const transformedClubs = transformClubs(clubs);
  const transformedBuildings = transformBuildings(buildings);
  const transformedFacilities = transformFacilities(facilities);
  return (
    <>
      {context && (
        <VenuesContext.Provider value={transformedVenues}>
          <FiltersContext.Provider
            value={{
              clubs: transformedClubs,
              facilities: transformedFacilities,
              buildings: transformedBuildings,
            }}
          >
            {/* <LayoutContext.Provider value={pageContext.breadcrumbs}> */}
            <DynamicTemplate context={context} />
            {/* </LayoutContext.Provider> */}
          </FiltersContext.Provider>
        </VenuesContext.Provider>
      )}
    </>
  );
};

StoryBlokPreview.propTypes = {};

export const query = graphql`
  query AirtableQuery {
    venues: allAirtableVenues {
      edges {
        node {
          id
          data {
            ID
            Name
            Status
            Building_Type {
              id
              data {
                Name
                Hero_icon
              }
            }
            Minimum_Age
            Maximum_Age
            Nearby_Venues {
              id
              data {
                ID
              }
            }
            Contact_Number
            Contact_Email
            Address_Line_One
            Address_Line_Two
            Town_or_City
            County
            Postcode
            Local_Authority
            Region
            Latitude
            Longitude
            Facilities {
              data {
                Name
                Location
              }
            }
            Page_on_Website
            Venue_Website
            Booking_Link
            Availability_Link
            Registration_Link
            Permanent_Booking_Cancellation
            Pickup_Late_Fee
            Pick_up_and_Drop_Off_Instructions
            No_Show_Fee
            Daily_Disc
            Weekly_Disc
            Sibling_Disc
            Service_List_Info_Title
            Service_List_Info_Copy
            Inspection_Standards {
              data {
                Visible_On_Website
                Reference_Number
                Type
                Rating
                Application_Date
                Registration_Date
                Resigned_Date
                Report_Link
              }
            }
            Venue_clubs {
              data {
                Name
                Club_Type {
                  id
                  data {
                    Name
                    Card_Icon
                  }
                }
                Description
              }
            }
            Services {
              data {
                Service_Reference
                Club_Type {
                  data {
                    Name
                    Card_Icon
                  }
                }
                Club_Name
                Days_Running
                Term
                Start_Date
                End_Date
                Session_Start_Time
                Session_End_Time
                Current_Price
                Tiered_Pricing
                Booking_Start_Date
                Booking_End_Date
                Discounted_Price
                Early_Finish
                Late_Finish
                Late_Fee
                Booking_Cut_Off
                Cancellation_Period
                Registration_Fee
                Events {
                  id
                  data {
                    Activity
                    Description
                    Date
                    Image
                    Additional_Cost
                    Type
                    Discounted_Cost
                    Event_Name
                    Group
                    Start_Time
                    End_Time
                  }
                }
              }
            }
          }
        }
      }
    }
    clubs: allAirtableClubTypes {
      edges {
        node {
          data {
            Card_Icon
            Icon
            Name
            Website_Filter_Item
            Website_Table_Row_Colour
          }
        }
      }
    }
    buildings: allAirtableBuildingTypes {
      edges {
        node {
          data {
            Name
            Icon
            Hero_Icon
            Website_Filter_Item
          }
        }
      }
    }
    facilities: allAirtableFacilities {
      edges {
        node {
          data {
            Card_Icon
            Icon
            Name
            Website_Filter_Item
          }
        }
      }
    }
  }
`;

export default StoryBlokPreview;
