const mapAirtableResponse = x => x.edges.map(y => y.node.data);
const moment = require('moment');

const transformVenues = venues => {
  return mapAirtableResponse(venues).map(venue => {
    return {
      id: venue.ID,
      name: venue.Name,
      status: venue.Status,
      buildingType: venue.Building_Type?.length
        ? {
            name: venue.Building_Type[0]?.data?.Name,
            icon: venue.Building_Type[0]?.data?.Hero_icon,
          }
        : null,
      age: {
        min: venue.Minimum_Age,
        max: venue.Maximum_Age,
      },
      nearbyVenues: venue.Nearby_Venues?.map(nearbyVenue => nearbyVenue.data?.ID),
      contactNumber: venue.Contact_Number,
      contactEmail: venue.Contact_Email,
      address: {
        line1: venue.Address_Line_One,
        line2: venue.Address_Line_Two,
        townCity: venue.Town_or_City,
        county: venue.County,
        postcode: venue.Postcode,
        localAuthority: venue.Local_Authority,
        region: venue.Region,
      },
      coords: {
        lat: venue.Latitude,
        lng: venue.Longitude,
      },
      facilities: venue.Facilities?.map(facility => ({
        name: facility.data?.Name,
        location: facility.data?.Location,
      })),
      links: {
        website_url: venue.Page_on_Website,
        venueWebsite: venue.Venue_Website,
        bookingLink: venue.Booking_Link,
        availabiltyLink: venue.Availability_Link,
        registrationLink: venue.Registration_Link,
      },
      permanentBookingCancellation: venue.Permanent_Booking_Cancellation,
      pickupLateFee: venue.Pickup_Late_Fee,
      pickupAndDropoffInstructions: venue.Pick_up_and_Drop_Off_Instructions,
      noShowFee: venue.No_Show_Fee,
      dailyDiscount: venue.Daily_Disc,
      weeklyDiscount: venue.Weekly_Disc,
      siblingDiscount: venue.Sibling_Disc,
      serviceListInfoTitle: venue.Service_List_Info_Title,
      serviceListInfoCopy: venue.Service_List_Info_Copy,
      termsLink: venue.Terms_Link,
      inspectionStandards: venue.Inspection_Standards?.filter(
        inspectionStandard => inspectionStandard.data?.Visible_On_Website, // bug
      )?.map(inspectionStandard => ({
        referenceNumber: inspectionStandard.data?.Reference_Number,
        type: inspectionStandard.data?.Type,
        rating: inspectionStandard.data?.Rating,
        applicationDate: inspectionStandard.data?.Application_Date,
        registrationDate: inspectionStandard.data?.Registration_Date,
        resignedDate: inspectionStandard.data?.Resigned_Date,
        reportLink: inspectionStandard.data?.Report_Link,
      }))[0],
      clubs: venue.Venue_clubs?.map(venueClub => ({
        name: venueClub.data?.Name,
        clubType: venueClub.data?.Club_Type[0]?.data?.Name,
        description: venueClub.data?.Description,
      })),
      services: venue.Services?.filter(
        service =>
          moment(service.data?.Booking_Start_Date).isSameOrBefore(moment()) &&
          moment(service.data?.Booking_End_Date).isAfter(moment()),
      ).map(service => ({
        clubType: service?.data?.Club_Type[0]?.data?.Name,
        reference: service.data?.Service_Reference,
        name: service.data?.Club_Name,
        daysRunning: service.data?.Days_Running,
        term: service.data?.Term,
        startDate: moment(service.data?.Start_Date).format('ddd Do MMM'),
        endDate: moment(service.data?.End_Date).format('ddd Do MMM'),
        startTime: service.data?.Session_Start_Time,
        endTime: service.data?.Session_End_Time,
        currentPrice: service.data?.Current_Price,
        tieredPricing: service.data?.Tiered_Pricing,
        prices: service.data?.Service_prices?.map(servicePrice => {
          return {
            bookingType: servicePrice?.data?.Booking_type,
            price: servicePrice?.data?.Price,
            subsidisedPrice: servicePrice.data?.Subsidised_price,
          };
        }),
        discountedPrice: service.data?.Discounted_Price,
        bookingStartDate: service.data?.Booking_Start_Date,
        bookingEndDate: service.data?.Booking_End_Date,
        earlyFinish: service.data?.Early_Finish,
        lateFee: service.data?.Late_Fee,
        bookingCutOff: service.data?.Booking_Cut_Off,
        cancellationPeriod: service.data?.Cancellation_Period,
        registrationFee: service.data?.Registration_Fee,
        events: service.data?.Events?.map(event => ({
          activity: event.data?.Activity,
          description: event.data?.Description,
          date: event.data?.Date,
          image: event.data?.Image,
          additionalCost: event.data?.Additional_Cost,
          type: event.data?.Type,
          discountedCost: event.data?.Discounted_Cost,
          eventName: event.data?.Event_Name,
          group: event.data?.Group,
          startTime: event.data?.Start_Time,
          endTime: event.data?.End_Time,
        })),
      })),
    };
  });
};

module.exports = transformVenues;
